import { faInstagram, faBehance, faLinkedin, IconDefinition } from "@fortawesome/free-brands-svg-icons";
import { config } from "../../config/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconLink, SocialIcons } from "../../util/Styles";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

export const SocialLinks = (props: { hasEmail?: boolean }) => {
  return (
    <SocialIcons>
      {config.contactInfo.socialLinks.instagram && (
        <SocialLink icon={faInstagram} link={config.contactInfo.socialLinks.instagram} />
      )}
      {config.contactInfo.socialLinks.behance && (
        <SocialLink icon={faBehance} link={config.contactInfo.socialLinks.behance} />
      )}
      {config.contactInfo.socialLinks.linkedin && (
        <SocialLink icon={faLinkedin} link={config.contactInfo.socialLinks.linkedin} />
      )}
      {props.hasEmail && <SocialLink icon={faEnvelope} link={`mailto:${config.contactInfo.email}`} />}
    </SocialIcons>
  );
};

export const SocialLink = (props: { icon: IconDefinition; link: string }) => {
  return (
    <IconLink aria-label={"social-link"} href={props.link} target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon icon={props.icon} />
    </IconLink>
  );
};
