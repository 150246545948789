import React from "react";
import massiveScarEraLogo from "../../assets/images/clients/massiveScarEraLogo.png";
import manistarLogo from "../../assets/images/clients/manistarLogo.png";
import psychopathLogo from "../../assets/images/clients/psychopathLogo.png";
import arakadresLogo from "../../assets/images/clients/arakadresLogo.png";
import andishiaLogo from "../../assets/images/clients/andishiaLogo.png";
import { useTranslation } from "react-i18next";
import { ClientsWrapper } from "../../util/Styles";
import { IClinet } from "../../network/APITypes";

const clients: IClinet[] = [
  { name: "Massive Scar Era", logo: massiveScarEraLogo, link: "https://massivescarera.bandcamp.com/album/assyad-2" },
  { name: "Manistar", logo: manistarLogo },
  { name: "Arakadres", logo: arakadresLogo },
  { name: "Psychopath", logo: psychopathLogo },
  { name: "Andishia", logo: andishiaLogo },
];

const Clients: React.FC = () => {
  const { t } = useTranslation();

  const renderClinetImage = (client: IClinet, index: number) => {
    return (
      <img
        src={client.logo}
        alt={client.name}
        key={index}
        style={{
          filter: localStorage.getItem("theme") === "dark" ? "invert(1)" : "none",
        }}
        loading="lazy"
      />
    );
  };

  return (
    <ClientsWrapper>
      <h2>{t("screen.clients.title")}</h2>
      <div>
        {clients.map((client, index) =>
          client.link ? (
            <a key={index} href={client.link}>
              {renderClinetImage(client, index)}
            </a>
          ) : (
            renderClinetImage(client, index)
          ),
        )}
      </div>
    </ClientsWrapper>
  );
};

export default Clients;
