import animatedLogo from "../assets/images/Logo.mp4";
import logoStill from "../assets/images/LogoStill.webp";
import hexagon from "../assets/images/Hexagon.svg";

export const config = {
  site: {
    name: "ZEAM Studio",
    animatedLogo: animatedLogo,
    logoStill: logoStill,
    url: "https://zeam.studio",
  },
  backend: {
    apiBaseUrl: process.env.REACT_APP_API_BASE_URL as string,
  },
  decoration: {
    logoBackground: hexagon,
  },
  animation: {
    linkAnimation: 1000,
    loadingLogoDuration: 1500,
    styleAnimationDuration: 350,
  },
  contactInfo: {
    email: "contact@zeam.studio",
    socialLinks: {
      linkedin: undefined,
      instagram: "https://www.instagram.com/zeam.studio/",
      behance: "https://www.behance.net/zeinab-gholami",
    },
  },
  modules: {
    backgroundMusic: false,
  },
};
